const string = {
  main: {
    title: '블록체인 개발 및 컨설팅',
    subtitle:
      '블록체인 전반에 대한 컨설팅부터 개발까지\n 전 단계를 지원해드립니다.',
    // button1: '웹지갑 DEMO',
    // button2: '앱지갑 DEMO',
    // button3: '관리자 DEMO',
    button1: '블랍스 회사소개',
    button2: '플랫타 생태계',
    button3: '',
    introDownloadAlert: '모바일에서 실행해 주세요'
  },
  sub1: {
    title: '회사소개',
    description:
      '블랍스는 2017년 블록체인 분야의 연구개발을 시작으로 한국의 블록체인 기술을 선도하고자 하는 비전을 가지고 2018년 하이브리드 콜드월렛 개발과 함께 설립된 회사입니다. 블록체인을 기반으로 하는 다양한 영역에서 사업을 수행하고 있으며, 수많은 포트폴리오를 보유하고 있습니다.',
    grid1main: '보안을 위한',
    grid1sub: '블랍스 콜드월렛',
    grid2main: '블록체인',
    grid2sub: '협회 및 인증',
    grid3main: '블록체인 메인넷',
    grid3sub: '중국과 공동 개발',
    grid4main: 'Reverse ICO 플랫폼',
    grid4sub: '플랫타',
    grid5main: '컨텐츠 서비스를 위한',
    grid5sub: 'DAPP 패키지',
    grid6main: '온/오프라인',
    grid6sub: '블록체인 결제 시스템',
  },
  sub2: {
    title: '업무안내',
    subtitle:
      '일반적으로 블록체인 사업은 아래와 같은 단계로 진행되며, 미팅을 통해 고객의 사업특성에 맞게 최종 방향을 결정하게 됩니다.',
  },
  sub3: {
    table1title: '코인개발',
    table1des: `코인은 이더리움(ERC-20)기반의 토큰을 제작해드리고 있습니다.
    고객사업에 맞추어 필요한 기능들을 추가 할 수 있으며, 일반적으로 아래와 같은 기능들을 옵션으로 제공하고 있습니다.`,
    table2title: '백서제작',
    table2des: `전문적으로 백서를 제작하는 인력이 고객의 사업을 부석하여 백서를 제작해 드립니다.\n필요한 경우 다양한 국가의 언어로 번역을 진행합니다.`,
    table3title: '소개페이지',
    table3des:
      '제작한 코인에 대해 내용을 쉽게 알 수 있고, 필요 시 문의를 받거나 ICO를 진행할 수 있는 홍보용 원페이지 웹사이트를 제작해 드립니다.',
    rows: [
      `발매량 증가기능`,
      `발매량 소각기능`,
      '거래중지 /재개기능',
      '클라우드 세일 기능',
      '에어드랍 기능',
    ],
  },
  sub4: {
    title: '지갑개발',
    description:
      '오랜기간 서비스를 통해 보안성 및 안정성이 입증된 블랍스 하이브리드 콜드월렛의 코어 솔루션을 활용하여 고객사의 코인을 안전하게 보관할 수 있는 전용 지갑을 제작해드립니다. 코인 지갑은 웹 및 모바일(안드로이드 및 IOS)버전으로 구성되어 있으며 고객사에서 필요한 기능들을 커스터마이징 하여 운영할 수 있습니다.',
    item1:
      'Private Key를 개인이 보관하기 때문에 서버의 해킹으로 부터 안전합니다. 분실하더라도 복구단어 등의 보안수단을 통해 완벽한 복구가 가능합니다. 스마트폰의 지문인식 기능을 활용, 더 강력한 보안을 제공합니다. 코인 지갑은 웹 및 모바일(안드로이드 및 IOS)버전으로 구성되어 있으며 고객사에서 필요한 기능들을 커스터마이징 하여 운영할 수 있습니다.',
    item1title: '안전성',
    item2:
      '직관적이고 간편한 스마트폰 IO로 누구나 편리하게 사용할 수 잇습니다. 사용자는 수수료 지불방식, 트랜잭션 컨펌등 복잡한 내용을 알지 못하더라도 쉽게 이용할 수 있습니다.',
    item2title: '편의성',
    item3:
      '고객의 사업에 맞는 다양한 기능들을 DAPP기반으로 확장하여 서비스가 가능합니다. 블랍스에서 준비중인 결제 플랫폼과 호환이 가능할 예정이며, 다양한 영역에서 코인 사용이 가능합니다.',
    item3title: '확장성',
  },
  sub5: {
    title: 'DAPP개발',
    description:
      'DAPP개발을 통해 고객사에서 제작한 지갑에 다양한 기능들을 자유롭게 추가할 수 있습니다.',
    item1title: '코인 결제연동',
    item1des:
      '운영되고 있거나 새로만든 사이트 또는 앱에서 개발한 코인을 활용하여 다양한 서비스가 가능하도록 구축',
    item2title: '계약 및 문서관리',
    item2des:
      '회사내외에서 이루어지는 계약 및 주고받는 문서들을 블록체인을 통해 투명하게 관리할 수 있습니다.',
    item3title: '자산 분할 관리',
    item3des:
      '고객이 소유하고 있는 자산을 디지털화 하여 작은 단위로 분할하고, 이를 블록체인을 통해 소유 및 이관할 수 있습니다.',
    item4title: '이력관리',
    item4des:
      '고객이 보유하고 있는 생산설비,장비,자산 등을 유지보수하면서 발생하는 이력들을 블록체인을 통해 관리할 수 있습니다.',
  },
};

export default string;
