import React, { Component } from 'react';
import string from '../String';
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class Header extends Component {
  state = {
    navList: [
      {
        title: string.sub1.title,
        index: 1,
      },
      {
        title: string.sub2.title,
        index: 2,
      },
      {
        title: string.sub3.table1title,
        index: 3,
      },
      {
        title: string.sub4.title,
        index: 4,
      },
      {
        title: string.sub5.title,
        index: 5,
      },
      {
        title: '문의하기',
        index: 6,
      },
    ],
  };

  scrollToPage = (event, index) => {
    const pos = document.getElementById(`sub${index}`).offsetTop;
    window.scrollTo(0, pos);
  };

  render() {
    return (
      <div className="navigation">
        <nav className="navigation-container">
          <img
            className="navigation-logo"
            src={require('../img/1/logo.png')}
            alt="logo"
          />
          <ul className="navigation-list">
            {this.state.navList.map((value, index) => (
              <li key={`nav${index}`} className="navigation-list-item">
                <AnchorLink href={'#sub'+(index + 1)}>
                  {value.title}
                </AnchorLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="page-top-image-wrapper">
          <a href='#'>
            <img src={require('../img/page-up.png')} className="page-top-image"/>
          </a>
        </div>
      </div>
    );
  }
}
