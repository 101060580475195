import React, { Component } from 'react';
import Header from './Header';
import string from '../String';
export default class Main extends Component {
  state = {
    buttons: [
      {
        title: string.main.button1,
        index: 1,
      },
      {
        title: string.main.button2,
        index: 2,
      },
      {
        title: string.main.button3,
        index: 3,
      },
    ],
    selectedIdx: 0,
  };
  onClickDownload = () => {
    if (/Android/i.test(navigator.userAgent)) {
      global.location.href =
        'http://play.google.com/store/apps/details?id=com.jba';
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      global.location.href =
        'https://itunes.apple.com/kr/app/%EB%B8%94%EB%9E%8D%EC%8A%A4/id1428926701?mt=8';
    } else {
      alert(string.main.introDownloadAlert);
    }
  };
  render() {
    return (
      <div className="background-main">
        <div className="container">
          <Header />
          <div className="main">
            <div className="main-content">
              <div className="main-content-left">
                <h1>{string.main.title}</h1>
                <h4>{string.main.subtitle}</h4>
                <div className="main-content-left-btn">
                  <div
                    className="main-content-left-btn-item-clicked"
                    onClick={() => {
                      window.open('https://blobs.kr');
                    }}>
                    <span>{string.main.button1}</span>
                  </div>
                  {/* <div
                    className="main-content-left-btn-item"
                    onClick={()=>{window.open('https://flata.io');}}>
                    <span>{string.main.button2}</span>
                  </div> */}
                </div>
              </div>
              <div className="main-content-right">
                <div className="main-image-container">
                  <img
                    alt="mainImage desk"
                    src={require('../img/1/main-1.png')}
                    className="main-img1"
                  />
                  <img
                    alt="mainImage desk"
                    src={require('../img/1/m_main-1.png')}
                    className="main-img2"
                  />
                  <img
                    className="main-image-logo"
                    src={require('../img/1/logo.png')}
                    alt="logo"
                  />
                </div>
              </div>
              <div className="main-bottom-img desk">
                <img src={require('../img/1/scroll.png')} alt="scrollDown" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
