import React, { Component } from 'react';
import string from '../String';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class Footer extends Component {
  render() {
    return (
      <footer id='footer'>
        <div className='container'>
          <div className='footer-container'>
            <div className='company-info flex-row flex-column'>
              <div>
                <span className='blobs-coin-title'>회사정보</span>
              </div>
              <div className='m-t-3 m-b-2'>
                <span>(주) 블랍스</span>
                <span className='m-x-2'>|</span>
                <span>대표 유형석</span>
              </div>
              <div className='m-b-2'>
                <span>서울 서초구 남부순환로 2469 도요빌딩 4층</span>
              </div>
              <div className='m-b-3'>
                <span>
                  사업자등록번호 883-88-01187
                  <span className='m-x-2'>|</span>
                  통신판매업신고 2020-서울강남-00513호
                </span>
              </div>
              <div>
                <strong>
                  <span>
                    Email: support@blobs.kr
                    <span className='m-x-2'>|</span>
                    고객센터 : 02-423-4261(운영시간 : 10:00~18:00)
                  </span>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
