import React, { Component } from 'react';
import string from '../String';

export default class Sub2 extends Component {
  render() {
    return (
      <section className="background-sub2" id="sub2">
        <div className="non-width-container">
          <div className="sub2">
            <div className="sub2-header">
              <div className="sub2-header-container">
                <div className="sub2-header-title">
                  <span>{string.sub2.title}</span>
                </div>
                <div className="sub2-header-subtitle">
                  <span>{string.sub2.subtitle}</span>
                </div>
              </div>
            </div>
            <div className="sub2-content">
              <img
                src={require('../img/3/diagram.png')}
                alt="business-diagram"
                className="sub2-content-image desk"
              />
              <img
                src={require('../img/3/m_diagram.png')}
                alt="business-diagram"
                className="sub2-content-image mobile"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
