const formatDateNumber = num => {
  num += '';
  return num.length < 2 ? '0' + num : num;
};

// const formatDate = date => {
//   // if (date === null) return '-';
//   if (!date) return null;

//   let newDate = new Date(date);
//   let year = newDate.getFullYear();
//   let month = newDate.getMonth();
//   let day = newDate.getDate();

//   return `${year}-${formatDateNumber(month + 1)}-${formatDateNumber(day)}`;
// };

const formatDate = date => {
  return date
    .split('T')[0]
    .split('-')
    .join('.');
};

export { formatDate };
