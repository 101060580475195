import React, { Component } from 'react';
import string from '../String';
import Modal from '../components/Modal';

export default class Sub1 extends Component {
  state = {
    grids: [
      {
        main: string.sub1.grid1main,
        sub: string.sub1.grid1sub,
      },
      {
        main: string.sub1.grid2main,
        sub: string.sub1.grid2sub,
      },
      {
        main: string.sub1.grid3main,
        sub: string.sub1.grid3sub,
      },
      // {
      //   main: string.sub1.grid4main,
      //   sub: string.sub1.grid4sub,
      // },
      {
        main: string.sub1.grid5main,
        sub: string.sub1.grid5sub,
      },
      {
        main: string.sub1.grid6main,
        sub: string.sub1.grid6sub,
      },
    ],
    modalVisible: false,
    selectedIdx: 0,
  };
  render() {
    return (
      <section className="background-sub1" id="sub1">
        <Modal
          visible={this.state.modalVisible}
          selectedIdx={this.state.selectedIdx}
          onClose={() => {
            this.setState({
              modalVisible: false,
            });
          }}
          onChange={idx => {
            this.setState({
              selectedIdx: idx,
            });
          }}
        />
        <div className="container">
          <div className="sub1">
            <div className="sub1-header">
              <div className="sub1-header-title">
                <span>{string.sub1.title}</span>
              </div>
              <div className="sub1-header-subtitle">
                <span>{string.sub1.description}</span>
              </div>
            </div>
            <div className="sub1-content">
              {this.state.grids.map((value, index) => (
                <div
                  key={`${index}`}
                  className={`background-sub1-item${index +
                    1} sub1-content-item`}
                  onClick={() => {
                    this.setState({
                      modalVisible: true,
                      selectedIdx: index,
                    });
                  }}>
                  <div className="sub1-content-item-icon">
                    <img
                      src={require('../img/2/plus.png')}
                      alt="moreIcon"
                      className="sub1-content-item-icon-img desk"
                    />
                    <img
                      src={require('../img/2/m_plus.png')}
                      alt="moreIcon"
                      className="sub1-content-item-icon-img mobile"
                    />
                  </div>
                  <div className="sub1-content-item-description">
                    <span className="sub1-content-item-description-title">
                      {value.main}
                    </span>
                    <span className="sub1-content-item-description-sub">
                      {value.sub}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
