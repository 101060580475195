import React, { Component } from 'react';
import string from '../String';

export default class Sub5 extends Component {
  render() {
    let rows = [];
    for (let i = 1; i <= 4; i++) {
      let title = `item${i}title`;
      let des = `item${i}des`;
      rows.push(
        <div key={`sub5${i}`} className="sub5-content-row">
          <div className="sub5-content-row-icon">
            <img
              src={require(`../img/6/icon${i}.png`)}
              alt="icon"
              className="sub5-content-row-icon-img desk"
            />
            <img
              src={require(`../img/6/m_icon${i}.png`)}
              alt="icon"
              className="sub5-content-row-icon-img mobile"
            />
          </div>
          <div className="sub5-content-row-item">
            <div className="sub5-content-row-item-title">
              <span>{string.sub5[title]}</span>
            </div>
            <div className="sub5-content-row-item-des">
              <span>{string.sub5[des]}</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <section className="background-sub5" id="sub5">
        <div className="container">
          <div className="sub5">
            <div className="sub5-header">
              <div className="sub5-header-title">
                <span>{string.sub5.title}</span>
              </div>
              <div className="sub5-header-des">
                <span>{string.sub5.description}</span>
              </div>
            </div>
            <div className="sub5-content">{rows}</div>
          </div>
        </div>
      </section>
    );
  }
}
