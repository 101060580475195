import React, { Component } from 'react';
import { Table, Pagination, Modal, Input, Popconfirm, message } from 'antd';
import { httpGet, httpUrl, httpPost } from '../api/httpclient';
import { formatDate } from '../util';
import QnaModal from '../components/QnaModal';
import QnaRequest from '../components/QnaRequest';
const { Column } = Table;

export default class Sub6 extends Component {
  state = {
    columns: [
      { title: '' },
      { title: '번호' },
      { title: '회사' },
      { title: '제목' },
      {
        title: '등록일',
      },
      { title: 'empty' },
    ],
    mColumns: [
      { title: '' },
      { title: '' },
      { title: '회사' },
      { title: '제목' },
      { title: '' },
    ],
    tableData: {},
    answer: {},
    selectedIdx: 0,
    current: 0,
    qnaVisible: false,
    password: '',
    popupVisible: false,
  };

  info = (idx, password) => {
    httpGet(httpUrl.qnaDetail, [idx, password], {}).then(res => {
      if (res.data !== null) {
        this.setState({
          selectedIdx: this.state.selectedIdx === idx ? 0 : idx,
          answer: res.data,
        });
      } else {
        Modal.info({
          title: '비밀번호 확인',
          content: (
            <div>
              <p>비밀번호가 틀렸습니다.</p>
            </div>
          ),
        });
        this.setState({
          selectedIdx: this.state.selectedIdx === idx ? 0 : idx,
          answer: {
            content: '비밀글입니다.',
            answer: '비밀글입니다.',
          },
        });
      }
    });
  };

  handleVisibleChange = visible => {
    if (!visible) {
      this.setState({ popupVisible: visible });
      return;
    }
    this.setState({ popupVisible: visible });
  };

  confirm = () => {
    let password = null;
    const { answer } = this.state;
    password = prompt('비밀번호를 입력해주세요.');
    if (password !== null) {
      httpPost(httpUrl.qnaDelete, [], {
        idx: answer.idx,
        password: password,
        question: answer.content,
        secret: answer.secret ? true : false,
        title: answer.title,
      }).then(res => {
        this.fetchList(10, 1);
      });
    }
  };

  cancel = () => {
    this.setState({ popupVisible: false });
  };

  fetchList = (pageSize, pageNum) => {
    // httpGet(httpUrl.qnaList, [pageSize, pageNum], {}).then(res => {
    //   this.setState({
    //     tableData: res.data,
    //     current: res.data.currentPage,
    //   });
    // });
  };

  onChange = page => {
    this.setState({
      selectedIdx: 0,
    });
    this.fetchList(10, page);
  };

  componentDidMount() {
    this.fetchList(10, 1);
  }

  renderTableHeader = rows => {
    return rows.map((value, index) => {
      return (
        <th className="sub6-table-header-head">
          {value.title === 'empty' ? (
            <div
              className="sub6-table-header-create-btn"
              onClick={() => {
                this.setState({
                  qnaVisible: true,
                });
              }}>
              <img
                src={require('../img/2/plus.png')}
                alt="board-create-button"
              />
            </div>
          ) : (
            <div>{value.title}</div>
          )}
        </th>
      );
    });
  };

  renderTableData = () => {
    if (this.state.tableData.data) {
      return this.state.tableData.data.map((value, index) => {
        const { idx, company, title, createDate } = value; //destructuring
        return [
          <tr className="sub6-table-body-row desk">
            <td></td>

            <td>{idx}</td>
            <td>{company}</td>
            <td
              className="sub6-table-body-row-title"
              onClick={() => {
                if (value.secret === 1 && this.state.selectedIdx !== idx) {
                  let password = prompt('비밀번호를 입력해주세요.');
                  this.info(value.idx, password);
                } else {
                  this.setState({
                    selectedIdx: this.state.selectedIdx === idx ? 0 : idx,
                    answer: value,
                  });
                }
              }}>
              {title}
            </td>
            <td>{formatDate(createDate)}</td>
            <td></td>
          </tr>,
          <tr className="sub6-table-body-row mobile">
            <td></td>
            <td>{idx}</td>
            <td>{company}</td>
            <td
              className="sub6-table-body-row-title"
              onClick={() => {
                if (value.secret === 1 && this.state.selectedIdx !== idx) {
                  let password = prompt('비밀번호를 입력해주세요.');
                  this.info(value.idx, password);
                } else {
                  this.setState({
                    selectedIdx: this.state.selectedIdx === idx ? 0 : idx,
                    answer: value,
                  });
                }
              }}>
              <span>{title}</span>
              <span>{formatDate(createDate)}</span>
            </td>
          </tr>,
          this.state.selectedIdx === idx && (
            <tr className="sub6-table-body-expandleRow">
              <td colSpan={6}>
                <div className="sub6-table-body-expandleRow-question">
                  <div
                    style={{
                      marginRight: '20px',
                    }}>
                    <p>{this.state.answer.content}</p>
                  </div>
                  <div>
                    <Popconfirm
                      title="게시글을 삭제하시겠습니까?"
                      onConfirm={this.confirm}
                      onCancel={this.cancel}
                      okText="Yes"
                      cancelText="No"
                      placement="right">
                      <a href="#">삭제</a>
                    </Popconfirm>
                  </div>
                </div>
                <div className="sub6-table-body-expandleRow-answer">
                  <div className="sub6-table-body-expandleRow-icon">
                    <img
                      src={require('../img/7/icon1.png')}
                      alt="answer-icon"
                    />
                  </div>
                  <span>
                    {this.state.answer.answer
                      ? this.state.answer.answer
                      : '답변이 없습니다'}
                  </span>
                </div>
                {/* {this.state.answer.answer && (

                )} */}
              </td>
            </tr>
          ),
        ];
      });
    }
  };

  render() {
    return (
      <section className="background-sub4 background-sub6" id="sub6">
        <div
          className="container"
          style={{
            paddingBottom: '100px',
          }}>
          {/* <QnaModal
            visible={this.state.qnaVisible}
            onCancel={() => {
              this.setState({
                qnaVisible: false,
              });
            }}
            refresh={() => {
              this.fetchList(10, 1);
            }}
          /> */}
          <div className="sub6 desk">
            <div className="sub6-header">
              <h1>문의하기</h1>
            </div>
          </div>
          <div className="sub6 mobile">
            <div className="sub6-header-container">
              <div className="sub6-header">
                <div>
                  <h1>문의하기</h1>
                </div>
              </div>
            </div>
          </div>
          <QnaRequest/>
          {/* <div className="sub6 desk">
            <div className="sub6-header">
              <h1>문의하기</h1>
            </div>
            <table className="sub6-table ">
              <col width="*" />
              <col width="10%" />
              <col width="10%" />
              <col width="60%" />
              <col width="20%" />
              <col width="*" />
              <tr className="sub6-table-header">
                {this.renderTableHeader(this.state.columns)}
              </tr>
              <tbody className="sub6-table-body">
                {this.renderTableData()}
              </tbody>
            </table>
          </div>
          <div className="sub6 mobile">
            <div className="sub6-header-container">
              <div className="sub6-header">
                <div>
                  <h1>문의하기</h1>
                </div>
              </div>
              <div
                className="sub6-header-create-btn"
                onClick={() => {
                  this.setState({
                    qnaVisible: true,
                  });
                }}>
                <img
                  src={require('../img/2/plus.png')}
                  alt="board-create-button"
                />
              </div>
            </div>
            <table className="sub6-table">
              <col width="5%" />
              <col width="10%" />
              <col width="20%" />
              <col width="65%" />
              <tr className="sub6-table-header">
                {this.renderTableHeader(this.state.mColumns)}
              </tr>
              <tbody className="sub6-table-body">
                {this.renderTableData()}
              </tbody>
            </table>
          </div>
          <div
            style={{
              marginTop: '20px',
              float: 'right',
            }}>
            <Pagination
              size="small"
              current={this.state.current}
              onChange={this.onChange}
              total={this.state.tableData.totalCount}
            />
          </div> */}
        </div>
      </section>
    );
  }
}
