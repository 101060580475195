import React, { Component } from 'react';
import string from '../String';

export default class Modal extends Component {
  state = {
    data: [
      {
        main: string.sub1.grid1main,
        sub: string.sub1.grid1sub,
        content: (
          <div>
            <div className="content-summary-text">
              블랍스 하이브리드 콜드 월렛은 기존 핫 월렛의 해킹 위험성과 <br/>
              콜드 월렛의 불편함을 보완한 3세대 카드형 지갑입니다.
            </div>
            <div className="content-image">
              <img src={require('../img/content-01.png')}/>
            </div>
            <div className="content-text">
              2018년 출시하여 현재 많은 유저들이 비트코인, 이더리움 등의 자산을 안전하게 보관하기 위하여 이용하고 있으며, 
              사용이 편리하고 부담스럽지 않은 가격으로 공급하고 있어 이용자들로부터 큰 호응을 얻고 있습니다. 
            </div>
          </div>
        ),
      },
      {
        main: string.sub1.grid2main,
        sub: string.sub1.grid2sub,
        content: (
          <div>
            <div className="content-summary-text">
              한국 블록체인 협회 정회원으로 <br/>
              블록체인 및 암호화폐 산업의 건전한 발전을 위해 노력하고 있습니다.
            </div>
            <div className="content-image">
              <img src={require('../img/content-02.png')}/>
            </div>
            <div className="content-summary-text">
              ISO-9001 인증
            </div>
            <div className="content-text">
              높은 품질의 서비스를 제공하기 위하여 ISO 인증을 취득하였습니다.
            </div>
            <div className="content-image">
              <img src={require('../img/content-03.png')}/>
            </div>
          </div>
        ),
      },
      {
        main: string.sub1.grid3main,
        sub: string.sub1.grid3sub,
        content: (
          <div>
            <div className="content-text">
              메인넷은 블랍스 플랫폼의 기반 인프라가 됩니다. 
            </div>
            <div className="content-image">
              <img src={require('../img/content-04.png')}/>
            </div>
            <div className="content-text">
              블랍스 플랫폼 버전 2.0에서는 토큰 지갑, 계약 관리, 에스크로, 위변조 보안 이렇게 총 4개의 서비스가 탑재 되어질 예정입니다.
              각 서비스는 내부적으로 MSA(Micro Service Architecture)로 구성되어 외부에서 REST API를 통해 접근할 수 있는 방법을 제공하며, 블랍스에서 자체 제공하는 DAPP 라이브러리를 사용하는 경우 보다 쉽게 플랫폼 이용이 가능합니다.
            </div>
          </div>
        ),
      },
      // {
      //   main: string.sub1.grid4main,
      //   sub: string.sub1.grid4sub,
      //   content: (
      //     <div>
      //       <div className="content-image">
      //         <img src={require('../img/content-05.png')}/>
      //       </div>
      //       <div className="content-text">
      //         공감은 “정서적 산소＂라고 할 정도로 우리에게 중요한 감정입니다. 우리는 공감 덕분에 타인을 이해하고 타인과 연결됩니다.<br/>
      //         수많은 암호화폐 재단들이 암호화폐 생태계를 확장하기 위하여 많은 노력을 하였지만 변동성이 많은 암호화폐의 성격과 재단은 역량 미달로 결제와 생태계 확장의 한계를 보이고 문제점 노출로 부정적인 이미지가 있는 것이 오늘날의 암호화폐 시장의 현실입니다.<br/>
      //         플랫타 프로젝트는 이런 문제를 해결하기 위하여 기존에 사업을 영위하고 있는 견실한 기업들을 선별하여 실생활에 바로 활용 가능한 암호화폐 생태계를 구축하는 사업입니다. 코인을 담을 수 있는 DAPP 지갑을 오픈하였고, DAPP내에서 암호화폐 간 교환이나 이벤트를 진행합니다.<br/>
      //       </div>
      //     </div>
      //   ),
      // },
      {
        main: string.sub1.grid5main,
        sub: string.sub1.grid5sub,
        content: (
          <div>
            <div className="content-image">
              <img src={require('../img/content-06.png')}/>
            </div>
            <div className="content-text">
              DAPP이란 탈중앙화된 어플리케이션을 말합니다. 블랍스에서는 이더리움 기반의 DAPP 솔루션을 기반으로 다양한 서비스를 블록체인과 접목하는 작업을 진행하고 있습니다.
            </div>
          </div>
        ),
      },
      {
        main: string.sub1.grid6main,
        sub: string.sub1.grid6sub,
        content: (
          <div>
            <div className="content-text">
              블랍스는 자체 개발한 DAPP기반 솔루션에 결제시스템을 적용하여 간편한 블록체인 결제 인프라를 구축합니다.  
              대표적인 사례로 FK COIN이 있습니다.
            </div>
            <div className="content-image">
              <img src={require('../img/content-07.png')}/>
            </div>
            <div className="content-text">
            FK코인을 보관할 수 있는 지갑과 호텔, 쇼핑몰에서 지갑으로 간편하게 결제할 수 있는 시스템을 제공하여 실제 사용할 수 있는 코인이라는 가치를 부여하였고, 국내 및 해외 거래소에 상장하여 누구나 쉽게 FK코인을 구매하여 이용할 수 있도록 하였습니다.
            </div>
          </div>
        ),
      },
    ],
    body: '',
  };
  render() {
    const { data } = this.state;
    const { visible, selectedIdx, onClose, onChange } = this.props;
    return (
      <div className={`modal ${visible ? '' : 'invisible'}`}>
        <div className="modal-content desk">
          <div className="close">
            <div
              onClick={() => {
                onClose();
              }}>
              &times;
            </div>
          </div>
          <div className="modal-content-header">
            {data.map((value, index) => (
              <div
                key={`modal${index}`}
                className={`modal-content-header-item ${
                  selectedIdx === index ? 'clicked' : ''
                }`}
                onClick={() => {
                  onChange(index);
                }}>
                <span>{value.main}</span>
                <span>{value.sub}</span>
              </div>
            ))}
          </div>
          <div className="modal-content-body">
            <div>
              <span>{`${data[selectedIdx].main}`}</span>
            </div>
            <div
              style={{
                fontSize: '24px',
              }}>
              <span>{`${data[selectedIdx].sub}`}</span>
            </div>
            <div>
              <span>{data[selectedIdx].content}</span>
            </div>
          </div>
        </div>
        <div className="modal-content mobile">
          <div className="close">
            <div
              onClick={() => {
                onClose();
              }}>
              &times;
            </div>
          </div>
          <div className="modal-content-body">
            <div>
              <span>PC버전에서 확인해 주세요</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
