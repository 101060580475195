import React, { Component } from 'react';

import { Spin } from 'antd';
// import './index.css';
import './css/index.scss';
import "./css/common.scss";
import Main from './pages/Main';
import Sub1 from './pages/Sub1';
import Sub2 from './pages/Sub2';
import Sub3 from './pages/Sub3';
import Sub4 from './pages/Sub4';
import Sub5 from './pages/Sub5';
import Sub6 from './pages/Sub6';
import Footer from './pages/Footer';

class App extends Component {
  render() {
    return (
      <div className="app">
        <div
          id="loadingSpinner"
          style={{
            display: 'none',
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            top: 0,
            left: 0,
            textAlign: 'center',
            zIndex: 99999,
          }}>
          <Spin style={{ position: 'absolute', top: '50%', zIndex: 99999 }} />
        </div>
        <div className="sticky-icon desk">
          <div className="sticky-icon-container">
            <div className="circle">
              <img
                src={require('./img/1/appDownload.png')}
                alt="download-icon"
              />
            </div>
            <span>앱다운로드</span>
          </div>
          <div className="sticky-icon-container">
            <div className="circle">
              <img src={require('./img/1/card.png')} alt="card-icon" />
            </div>
            <span>카드구매</span>
          </div>
        </div>
        <Main />
        <Sub1 />
        <Sub2 />
        <Sub3 />
        <Sub4 />
        <Sub5 />
        <Sub6 />
        <Footer />
      </div>
    );
  }
}

export default App;
