import React, { Component } from 'react';
import string from '../String';

export default class Sub3 extends Component {
  state = {
    tables: [
      {
        title: string.sub3.table1title,
        description: string.sub3.table1des,
        add: true,
      },
      {
        title: string.sub3.table2title,
        description: string.sub3.table2des,
      },
      {
        title: string.sub3.table3title,
        description: string.sub3.table3des,
      },
    ],
  };

  render() {
    const rows = string.sub3.rows.map((value, index) => {
      return (
        <div className="sub3-table-row-col2-circle" key={`sub3${index}`}>
          <div className="sub3-table-row-col2-circle-icon">
            <img
              src={require(`../img/4/sub3-icon${index + 1}.png`)}
              alt="circleicon"
              className="sub3-table-row-col2-circle-icon-img desk"
            />
            <img
              src={require(`../img/4/m_sub3-icon${index + 1}.png`)}
              alt="circleicon"
              className="sub3-table-row-col2-circle-icon-img mobile"
            />
          </div>
          <span>{value}</span>
        </div>
      );
    });

    return (
      <section className="container" id="sub3">
        <div className="sub3-container">
          <div className="sub3-title">
            <h1>{string.sub3.table1title}</h1>
          </div>
          <div className="sub3">
            <div className="sub3-table">
              {this.state.tables.map((value, index) => (
                <div
                  key={`sub3table${index}`}
                  className="sub3-table-row"
                  style={
                    index === 2
                      ? {
                          borderBottomWidth: 0,
                          marginBottom: 0,
                        }
                      : {}
                  }>
                  <div className="sub3-table-row-col1">
                    <div className="sub3-table-row-col1-container">
                      <span>{value.title}</span>
                    </div>
                  </div>
                  <div className="sub3-table-row-col2">
                    <span>{value.description}</span>
                    <div className="sub3-table-row-col2-add">
                      {value.add && rows}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="sub3-right">
              <img
                src={require('../img/4/img.png')}
                alt="sub3-right-img"
                className="sub3-right-img desk"
              />
              <img
                src={require('../img/4/m_img.png')}
                alt="sub3-right-img"
                className="sub3-right-img mobile"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
