import React, { Component } from 'react';
import string from '../String';

export default class Sub4 extends Component {
  render() {
    let rows = [];
    for (let i = 1; i <= 3; i++) {
      let title = `item${i}title`;
      let des = `item${i}`;
      rows.push(
        <div key={`sub4${i}`} className="sub4-right-table-row">
          <div className="sub4-right-table-row-col1">
            <img src={require(`../img/5/icon${i}.png`)} alt="icon" />
            <span>{string.sub4[title]}</span>
          </div>
          <div className="sub4-right-table-row-col2">
            <span>{string.sub4[des]}</span>
          </div>
        </div>
      );
    }
    return (
      <section className="background-sub4" id="sub4">
        <div className="sub4">
          <div className="sub4-left">
            <div className="sub4-left-container">
              <img
                src={require('../img/5/background.png')}
                alt="background"
                className="sub4-left-container-background desk"
              />
              <img
                src={require('../img/5/m_background.png')}
                alt="background"
                className="sub4-left-container-background mobile"
              />
              <img
                src={require('../img/5/icon5.png')}
                alt="background2"
                className="sub4-left-container-absolute-img desk"
              />
            </div>
          </div>
          <div className="sub4-right">
            <div className="sub4-right-container">
              <div className="sub4-right-header">
                <div className="sub4-right-header-title">
                  <span>{string.sub4.title}</span>
                </div>
                <div className="sub4-right-header-subtitle">
                  <span>{string.sub4.description}</span>
                </div>
              </div>
              <div className="sub4-right-table">{rows}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
