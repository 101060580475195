import React, { Component } from 'react';

import { Form, Modal, Input, Button, Checkbox, Row, Col } from 'antd';
import { httpPost, httpUrl } from '../api/httpclient';
const FormItem = Form.Item;

const QnaModal = Form.create()(
  class extends Component {
    state = {
      registryData: {},
      checked: false,
    };
    onSelect = (value, type) => {
      var data = this.state.registryData;
      if (type === 'title') {
        data.title = value;
      } else if (type === 'content') {
        data.content = value;
      } else if (type === 'name') {
        data.name = value;
      } else if (type === 'company') {
        data.company = value;
      } else if (type === 'password') {
        data.password = value;
      } else if (type === 'secret') {
        this.setState({
          checked: value,
        });
      }
      this.setState({
        registryData: data,
      });
    };

    onRegistry = e => {
      const { registryData, checked } = this.state;
      let createData = {
        ...registryData,
        secret: checked ? 1 : 0,
      };
      this.props.form.validateFields((err, values) => {
        if (!err) {
          httpPost(httpUrl.qnaCreate, [], createData).then(res => {
            this.onCancel();
            this.props.refresh();
          });
        }
      });
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    render() {
      const { checked } = this.state;
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const formHeaderLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 8,
            offset: 4,
          },
        },
      };
      return (
        <div className={`modal ${visible ? '' : 'invisible'}`}>
          <div className="modal-content-qna" style={{}}>
            <Form
              {...formItemLayout}
              onSubmit={this.onRegistry}
              layout="horizontal"
              colon={false}>
              <FormItem
                {...formHeaderLayout}
                label={
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}>
                    문의사항
                  </span>
                }>
                <Checkbox
                  style={{ float: 'right' }}
                  onChange={e => {
                    this.onSelect(e.target.checked, 'secret');
                  }}
                  checked={checked}>
                  비밀글
                </Checkbox>
              </FormItem>
              <FormItem label={<span>비밀번호&nbsp;</span>}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: '비밀번호를 입력해주세요!',
                    },
                  ],
                })(
                  <span>
                    <Input
                      onChange={e => {
                        this.onSelect(e.target.value, 'password');
                      }}
                    />
                  </span>
                )}
              </FormItem>
              <FormItem label={<span>제목&nbsp;</span>}>
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: '제목을 입력해주세요!',
                    },
                  ],
                })(
                  <Input
                    onChange={e => {
                      this.onSelect(e.target.value, 'title');
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>내용&nbsp;</span>}>
                {getFieldDecorator('content', {
                  rules: [
                    {
                      required: true,
                      message: '내용을 입력해주세요!',
                    },
                  ],
                })(
                  <Input.TextArea
                    style={{ minHeight: '160px' }}
                    onChange={e => {
                      this.onSelect(e.target.value, 'content');
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>이름&nbsp;</span>}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: '이름을 입력해주세요!',
                    },
                  ],
                })(
                  <Input
                    onChange={e => {
                      this.onSelect(e.target.value, 'name');
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>회사&nbsp;</span>}>
                {getFieldDecorator('company', {
                  rules: [
                    {
                      required: true,
                      message: '회사를 입력해주세요!',
                    },
                  ],
                })(
                  <Input
                    onChange={e => {
                      this.onSelect(e.target.value, 'company');
                    }}
                  />
                )}
              </FormItem>
              <FormItem {...tailFormItemLayout}>
                <Button
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    this.onCancel();
                  }}>
                  취소
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    this.onRegistry();
                  }}>
                  확인
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      );
    }
  }
);

export default QnaModal;

/**
 * <Row
              gutter={8}
              style={{
                lineHeight: '48px',
              }}>
              <Col span={1}>
                <span></span>
              </Col>
              <Col span={18}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <span
                    style={{
                      fontSize: 24,
                      fontWeight: 'bold',
                    }}>
                    문의사항
                  </span>
                  <Checkbox
                    onChange={e => {
                      this.onSelect(e.target.checked, 'secret');
                    }}
                    checked={checked}>
                    비밀글
                  </Checkbox>
                </div>
              </Col>
            </Row>
 */
